import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero/Hero";
import PicsGrid from "../components/PicsGrid/PicsGrid";
import Description from "../components/Description/Description";

export const lookbookImage = graphql`
  fragment lookbookImage on File {
    childImageSharp {
      fluid(maxHeight: 1200, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "lookbook/hero.jpg" }) {
      ...lookbookImage
    }
    image1: file(relativePath: { eq: "lookbook/LB-TRUSSARDI_1084.jpg" }) {
      ...lookbookImage
    }
    image2: file(relativePath: { eq: "lookbook/MILA-SCHON-2__0377.jpg" }) {
      ...lookbookImage
    }
    image3: file(relativePath: { eq: "lookbook/MILA-SCHON_0510.jpg" }) {
      ...lookbookImage
    }
    image4: file(relativePath: { eq: "lookbook/E-COM-HP_0428-2.jpg" }) {
      ...lookbookImage
    }
    image5: file(relativePath: { eq: "lookbook/The-Thorn_0693-2.jpg" }) {
      ...lookbookImage
    }
    image6: file(relativePath: { eq: "lookbook/3P2A2491.jpg" }) {
      ...lookbookImage
    }
  }
`;

const LookBook = ({ data }) => (
  <Layout pageUrl="lookbook">
    <SEO title="LookBook" />
    <Hero
      home={false}
      pageTitle="Lookbook"
      heroImage={data.heroImage.childImageSharp.fluid}
      heroAlt="Lookboook hero"
    />
    <PicsGrid
      pic1={data.image1.childImageSharp.fluid}
      alt1="Lookbook showcase"
      pic2={data.image2.childImageSharp.fluid}
      alt2="Lookbook showcase"
      pic3={data.image3.childImageSharp.fluid}
      alt3="Lookbook showcase"
    />
    <Description
      heading="The emotions combined with the utmost attention to detail"
      description="Our lookbooks can become a precious tool to represent collections and accessories. The realization can take place in our studio, with the support of dedicated props, or in any location chosen for the occasion."
    />
    <PicsGrid
      pic1={data.image4.childImageSharp.fluid}
      alt1="Lookbook showcase"
      pic2={data.image5.childImageSharp.fluid}
      alt2="Lookbook showcase"
      pic3={data.image6.childImageSharp.fluid}
      alt3="Lookbook showcase"
    />
  </Layout>
);

export default LookBook;
